import React from 'react';
import styles from './SelectSection.module.scss';
import classnames from 'classnames/bind';

const cx = classnames.bind(styles);

const SelectSection = ({goTo}) => {
    return (
        <div className={cx('bg')}>
            <div className={cx('select-section')}>
                <div className={cx('box')}>
                    <div className={cx('text-wrapper')}>
                        <h4>Are you</h4>
                        <p>A green coffee trader from farms or dry mill directly?</p>
                        <p>A green coffee buyer who receives green coffee through domestic distributors?</p>
                        <p>Willing to trade high-quality specialty coffee with farms directly?</p>
                        <p>A roastery owner of a massive producing commercial coffee?</p>
                    </div>
                    <button className={cx('buyer')} onClick={()=>goTo('buyer')}>BUYER</button>
                </div>
                <div className={cx('box')}>
                    <div className={cx('text-wrapper')}>
                        <h4>Are you</h4>
                        <p>A coffee farm owner capable of exporting coffee?</p>
                        <p>A green coffee trader from farms or dry mill directly?</p>
                        <p>A green coffee seller who sells green coffee through international distributors?</p>
                        <p>Willing to trade high-quality specialty coffee with roasters directly?</p>
                    </div>
                    <button className={cx('seller')} onClick={()=>goTo('seller')}>SELLER</button>
                </div>

            </div>
        </div>

    )
}

export default SelectSection;