import React, { Component } from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';


import LandingContainer from 'containers/___LandingV2/about/LandingContainer';
import Footer from 'containers/___LandingV2/Footer';
import SampleInfo from './containers/SampleInfo';

class App extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return (
      <React.Fragment>
          <div className="App">
            <Switch>
              <Route exact path="/" component={LandingContainer} />
              <Route exact path="/sample-info" component={SampleInfo} />
              <Route exact component={LandingContainer} />
            </Switch>

            <Footer/>
          </div>
      </React.Fragment>
    );
  }
}

export default App
