import React,  {Component, Suspense} from 'react';
import logo from 'images/logo/footer_logo.png';
import styles from './SampleInfo.module.scss';
import classnames from 'classnames/bind';
import Axios from 'axios';
import {country as countryName} from 'lib/codeToName'

const Flag = React.lazy(() => import('react-world-flags'));

const cx = classnames.bind(styles);

class SampleInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jsonList: []
        }
    }
    dispatchData = async () => {
        const sheetNumber = 1;
        const {data} = await Axios.get(`https://spreadsheets.google.com/feeds/cells/12rmQ32uClMdxY0ip2wLT-BLN8JBUzJjanJQPQo9bers/${sheetNumber}/public/full?alt=json`);
        console.log('data', data)
        let maxCol = 1;
        let maxRow = 1; 
        data.feed.entry.map(item => {
            if(Number(item.gs$cell.col) > maxCol) {
                maxCol = Number(item.gs$cell.col);
            }
            if(Number(item.gs$cell.row) > maxRow) {
                maxRow = Number(item.gs$cell.row);
            }
        })
        console.log('maxCol', maxCol);
        console.log('maxRow', maxRow);
        const jsonList = new Array(maxRow);
        let keyList = [];
        data.feed.entry.map((item, index) => {
            if(item.gs$cell.row === "1") {
                keyList.push(item.gs$cell.$t)
            }
        })
        for (let index = 0; index < maxRow; index++) {
            data.feed.entry.map((item) => {
                if(Number(item.gs$cell.row)>1) {
                    jsonList[Number(item.gs$cell.row)-2] = {
                        ...jsonList[Number(item.gs$cell.row)-2],
                        [keyList[Number(item.gs$cell.col)-1]]: item.gs$cell.$t
                    }
                }
            })
        }

        console.log('jsonList', jsonList)
        this.setState({jsonList});
    }
    componentDidMount() {
        this.dispatchData();
    }
    
    render() {
        return (
            <div className={cx("page")}>
                <div className={cx("subpage")}>
                    {this.state.jsonList.map(item => {
                        return (
                            <div className={cx('contents')} key={item.id}>
                                <img src={logo} alt=""/>
                                <section className={cx("origin-section")}>
                                    <h3 className={cx('id')}>
                                        {item.id} <span>Stock: {item.stock}</span>
                                    </h3>

                                    <div className={cx("flag-origin-region")}>
                                        <Suspense fallback={<p>...</p>}><Flag code={item.origin} height="20"/></Suspense>
                                        <p className={cx("origin-region")}>
                                            {countryName(item.origin)} - {item.region}
                                        </p>
                                    </div>
                                </section>
                                
                                <section className={cx("info-box")}>
                                    <section>
                                        <div className={cx('info-wrapper')}>
                                            <div className={cx('info')}>
                                                <p>Processing</p>
                                                <h5>{item.processing}</h5>
                                            </div>

                                            <div className={cx('info')}>
                                                <p>Grade</p>
                                                <h5>{item.grade}</h5>
                                            </div>

                                            <div className={cx('info')}>
                                                <p>Variety</p>
                                                <h5>{item.variety}</h5>
                                            </div>
                                        </div>
                                    </section>

                                    <section>
                                        <div className={cx('info-wrapper')}>
                                            <div className={cx('info')}>
                                                <p>Moisture</p>
                                                <h5>{item.moisture} (%)</h5>
                                            </div>

                                            <div className={cx('info')}>
                                                <p>Density</p>
                                                <h5>{item.density} (g/l)</h5>
                                            </div>

                                            <div className={cx('info')}>
                                                <p>Water Activity</p>
                                                <h5>{item.waterActivity} (AW)</h5>
                                            </div>
                                        </div>
                                    </section>

                                    {/* <section>
                                        <div className={cx('info-wrapper')}>
                                            <div className={cx('info')}>
                                                <p>Stock</p>
                                                <h5>{item.stock}</h5>
                                            </div>
                                        </div>
                                    </section> */}
                                </section>
                                {/* <div className={cx('left')}>
                                    <div className={cx('origin')}>                                            
                                        
                                        
                                    </div>
                                    
                                
                                </div> */}
    
                                {/* <div className={cx('right')}>
                                    <div className={cx('info-box')}>
                                        
    
                                        

    
                                        <div className={cx('impt')}>
                                            
                                            <div className={cx('info')}>
                                                <h5>Stock</h5>
                                                <h5>{item.stock}</h5>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div> */}
    
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }
}


export default SampleInfo