import React from 'react';

import classnames from 'classnames/bind';
import styles from './Footer.module.scss';

import logo from '../../../images/logo/footer_logo.png'
import { MdStayCurrentPortrait, MdPlace, MdMailOutline } from 'react-icons/md';
import { FaInstagram } from 'react-icons/fa';

const cx = classnames.bind(styles);

const Footer = () => {
    return (
        <div className={cx('container')}>
            <div className={cx('footer')}>
                <img src={logo} alt=""/>
                <h5>Contact Information</h5>
                <div><MdPlace/> <p>C2C PLATFORM, B1, 411, Baekjegobun-ro, Songpa-gu, Seoul, Republic of Korea (05621)</p></div>
                <div><MdStayCurrentPortrait/> <p>(+) 82 010 6236 1177</p></div>
                <div><MdMailOutline/> <p>contact@c2cplatform.com</p></div>
                <div><FaInstagram/> <a href="https://www.instagram.com/c2cplatform/">c2cplatform</a></div>
                
                <p>ⓒ 2020 C2C Platform Co., Ltd. All Rights Reserved.</p>
                
            </div>
        </div>
    )
}

export default Footer;