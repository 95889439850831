import React from 'react';

import styles from './WeAreSection.module.scss';
import classnames from 'classnames/bind';

import cqiImg from 'images/landing/certifications/cqi.png';
import aceImg from 'images/landing/certifications/ace.png';
import scaImg from 'images/landing/certifications/sca_white.png';

import CountUp from 'react-countup';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const cx = classnames.bind(styles);

const WeAreSection = () => {
    return (
        <div className={cx('we-section')}>
            <div className={cx('container')}>
                <div className={cx('row')}>
                    
                    {/* 
                    <div className={cx('item')}>
                        <h3>EXPERIENCE</h3>
                        <h1><CountUp end={10} duration={4}/></h1>
                        <p>10 YEARS EXPERIENCE IN THE COFFEE INDUSTRY</p>
                    </div>

                    <div className={cx('item')}>
                        <h3>TRADING EXPERIENCE</h3>
                        <h1><CountUp end={5} duration={5}/></h1>
                        <p>5 YEARS EXPERIENCE IN THE GREEN COFFEE TRADING MARKET</p>
                    </div> 
                    */}

                    
                    <div className={cx('item')}>
                        <h3>CONNECTION</h3>
                        {/* <h1>63</h1> */}
                        <h1><CountUp end={63} duration={3}/></h1>
                        <p>63 CUSTOMERS CONNECTIONS ON 2018</p>
                    </div>
                    <div className={cx('item')}>
                        <h3>TRANSACTION</h3>
                        {/* <h1>3</h1> */}
                        <h1><span>$</span><CountUp separator="," end={3200000} duration={0}/></h1>
                        <p>3 MILLION USD TRANSACTIONS ACHIEVED</p>
                    </div>
                </div>
                <div className={cx('row')}>
                    <div className={cx('item')}>
                        <h3>SELLERS POOL</h3>
                        <h1><CountUp separator="," end={13000} duration={2}/><span>+</span></h1>
                        <p>10,000+ SELLERS POOL INCLUDING COFFEE PRODUCERS AND DRY MILL OWNERS</p>
                    </div>

                    <div className={cx('item')}>
                        <h3>LOCATIONS</h3>
                        {/* <h1>2</h1> */}
                        <h1><CountUp end={2} duration={5}/></h1>
                        <p>
                            SOUTH KOREA BASED COMPANY
                            <br/>MAIN OFFICE LOCATED AT SAUDI ARABIA
                        </p>
                    </div>
                </div>

            </div>
            <div className={cx('member_of')}>
                <div className={cx('text-wrapper')}>
                    <p>We are members of SCA, ACE, CQI.</p>
                    <p>We participate in the SCA official events every year.</p>
                </div>
                <div className={cx('image-wrapper')}>
                    <LazyLoadImage effect="blur" width={100} height={100} src={scaImg} />
                    <LazyLoadImage effect="blur" width={100} height={100} src={cqiImg} />
                    <LazyLoadImage effect="blur" width={100} height={100} src={aceImg} />
                    {/* <img src={scaImg} alt=""/>
                    <img src={cqiImg} alt=""/>
                    <img src={aceImg} alt=""/> */}
                </div>
            </div>
        </div>
    )
}

export default WeAreSection;