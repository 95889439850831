import React from 'react';
import classnames from 'classnames/bind';
import styles from './MemberSection.module.scss';

import a from 'images/landing/profile_image/01.png';
import b from 'images/landing/profile_image/02.png';
import c from 'images/landing/profile_image/03.png';
import d from 'images/landing/profile_image/04.png';
import e from 'images/landing/profile_image/05.png';
import f from 'images/landing/profile_image/06.png';
import cupper from 'images/landing/spoon.png';
import jooye from 'images/landing/profile_image/yeeunjoo.png';
import immanuel from 'images/landing/profile_image/immanuel.jpg';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const cx = classnames.bind(styles);

const MemberSection = () => {
    return (
        <div className={cx('bg')}>
            
        
        <div className={cx('container')}>
            <div className={cx('item')}>
                <LazyLoadImage effect="blur" src={a}/>
                <div className={cx('text-box')}>
                    <h4>MYUNGSEOK SEO / Founder</h4>
                    <p>
                        I drink a cup of coffee on my desk slowly until it cools down. And I fall into deep thought. Is the coffee we drink truly happy? It is a delicious coffee to me. But we don’t recognize who’s blood and sweat hidden behind of this delightful taste. We never know until we meet them. We only believe in what we see. However, the truth of coffee that I saw with my own eyes was no longer happy. I will make this coffee happy from now on and I will change it. Now that you are reading my greetings, you have already become a team with us to make a happy coffee.
                    </p>
                </div>
            </div>

            <div className={cx('item')}>
                <LazyLoadImage effect="blur" src={b}/>
                <div className={cx('text-box')}>
                    <h4>JAY LEE / Director of Business Operations</h4>
                    <p>
                        C2C Platform pursue fair, clean, and comfortable transactions for both of our end users. We are the connection in direct trading among buyers and sellers. If you want a better trade, join us, and achieve a better life. Let us know what you need. We are always glad to help you out.
                    </p>
                </div>
            </div>

            <div className={cx('item')}>
                <LazyLoadImage effect="blur" src={immanuel}/>
                <div className={cx('text-box')}>
                    <h4>IMMANUEL REGINALD / Finacial Director</h4>
                    <p>
                    Coffee has been a major cash crop for centuries and an important beverage during the everyday life of many across the globe regardless of the ethnic diversity. This very fact gives me pride to work in an industry that serves the taste of many.
                    </p>
                </div>
            </div>

            <div className={cx('item')}>
                <LazyLoadImage effect="blur" src={c}/>
                <div className={cx('text-box')}>
                    <h4>FLOR COGHI / Global Sales Manager</h4>
                    <p>
                        Buying green coffee was never that easy! Make your coffee business more unique and profitable, using less of your time. Let C2C Platform know what coffee beans you need. We will make it happen. From the farm to your nearest port, we support every step.
                    </p>
                </div>
            </div>

            <div className={cx('item')}>
                <LazyLoadImage effect="blur" src={d}/>
                <div className={cx('text-box')}>
                    <h4>AJIN JO / Research Director</h4>
                    <p>
                        We do not only evaluate the green coffee based on the SCA standard but also based on the producer's criteria such as the type of soil, climatic conditions, cultivation technique, and quality of the processing. Also, to obtain a more precise quality control, we measure the moisture,  the water activity, and the density of green coffee beans. This is for you, your customers, and the producers. We want to share with you the special beans that we meet in daily life.
                    </p>
                </div>
            </div>

            <div className={cx('item')}>
                <LazyLoadImage effect="blur" src={e}/>
                <div className={cx('text-box')}>
                    <h4>JIMMY PYUN / Trade Manager</h4>
                    <p>
                        C2C Platform uses its coffee trading knowledge and expertise to create a fair and safe trading environment for sellers and buyers.  Our transaction process provides a pleasant and comfortable experience for our customers. Don't hesitate to join us. We will be there whenever you need us.
                    </p>
                </div>
            </div>

            <div className={cx('item')}>
                <LazyLoadImage effect="blur" src={f}/>
                <div className={cx('text-box')}>
                    <h4>YEONGHUN CHA / IT Developer</h4>
                    <p>
                        Find green coffee beans from all of the world, and obtain them from the conformity of your office. Using C2C Platform services will make you feel like home.  As C2C Platform developer, I promise your experience with us will be the best experience.
                    </p>
                </div>
            </div>

            <div className={cx('item')}>
                <LazyLoadImage effect="blur" src={jooye}/>
                <div className={cx('text-box')}>
                    <h4>YEEUN JOO / Designer</h4>
                    <p>
                        In the C2C Platform trading service we believe credibility between people is fundamental. We offer an excellent user experience interface so that you can easily access to C2C Platform. Your opinion is always welcome to improve our platform.
                    </p>
                </div>
            </div>
        </div>
        </div>
    )
}

export default MemberSection;