import React from 'react';

import styles from './FloatingButton.module.scss';
import classnames from 'classnames/bind';

import {MdKeyboardArrowUp} from 'react-icons/md';


const cx = classnames.bind(styles);

const FloatingButton = ({handleClick}) => {
    return (
        <button
            className={cx('floating')}
            onClick={handleClick}
        >
            <MdKeyboardArrowUp/>
            
        </button>
    )
}

export default FloatingButton;