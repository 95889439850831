import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import HeroSection from './HeroSection';
import SelectSection from './SelectSection';
import PartnerSection from './PartnerSection';
import MemberSection from './MemberSection';
import WeAreSection from './WeAreSection';
import FloatingButton from '../FloatingButton';

class LandingContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            theposition: 0
        }
        this.goTo = this.goTo.bind(this);
    }
    
    handleClick() {
        window.open('https://forms.gle/1VL1hKkjqxRH5VMG8', '_blank');
    }
    goTo(path) {
        this.props.history.push(`/${path}`);
    }

    floatingClick(e) {
        e.stopPropagation();
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }
    
    listenToScroll = () => {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop
    
        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight
    
        const scrolled = winScroll / height
    
        this.setState({
            theposition: scrolled,
        })
    }
    
    render() {
        return (
            <div>
                <HeroSection/>
                {/* <SelectSection
                    goTo={this.goTo}
                /> */}
                <PartnerSection/>
                <MemberSection/>
                {this.state.theposition > 0 && <FloatingButton handleClick={this.floatingClick}/>}
            </div>
        );
    }
}

export default withRouter(LandingContainer);