import React from 'react';

import styles from './PartnerSection.module.scss';
import classnames from 'classnames/bind';

import mapImg from 'images/landing/map_new01.png';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const cx = classnames.bind(styles);

const PartnerSection = () => {
    return (
        <div className={cx('bg')}>
            <div className={cx('partner')}>
                <h3>C2C PLATFORM PARTNERS</h3>
                <LazyLoadImage effect="blur" width={'100%'} src={mapImg}/>
                {/* <img src={mapImg} alt=""/> */}
                
            </div>
        </div>
    )
}

export default PartnerSection;